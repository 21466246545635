export class RateLimiter {
  private requestsPerSecond: number;
  private queue: Array<() => void> = [];
  private processing = false;

  constructor(requestsPerSecond: number) {
    this.requestsPerSecond = requestsPerSecond;
  }

  async wait(): Promise<void> {
    return new Promise((resolve) => {
      this.queue.push(resolve);
      if (!this.processing) {
        this.process();
      }
    });
  }

  private async process() {
    this.processing = true;
    while (this.queue.length > 0) {
      const resolve = this.queue.shift();
      if (resolve) {
        resolve();
        await new Promise(r => setTimeout(r, 1000 / this.requestsPerSecond));
      }
    }
    this.processing = false;
  }
}